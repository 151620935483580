@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

.theatreTitle {
  font-family: "Raleway", sans-serif;
  color: #f3be12;
  font-size: 4.5rem;
  font-weight: 600;
  margin-top: 0;
  line-height: 1;
  cursor: default;
}

.theatreTitleMobile {
  font-family: "Raleway", sans-serif;
  color: #f3be12;
  font-size: 3.8rem;
  font-weight: 600;
  margin-top: 0;
  line-height: 1;
  cursor: default;
}

.subInfo {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 1em;
  cursor: default;
}

.theatreInfo {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1em;
  cursor: default;
}

.theatreInfoMobile {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1.2rem;
  padding-top: 2em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  cursor: default;
}

.quote {
  font-family: "Raleway", sans-serif;
  color: #f3be12;
  font-weight: 500;
  font-size: 1.2rem;
}
.subInfoMobile {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1em;
  cursor: default;
}

.buttonText {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 1.5rem;
  font-weight: 500;
}

.heroTheatre {
  background-color: #0b0b0b;
  /* background-image: url(../../images/AboutBack.jpeg); */
  background-repeat: no-repeat;
  background-size: cover;
}
