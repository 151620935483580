@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Raleway", sans-serif;
  background-color: #0b0b0b;
}
.container {
  max-width: 95%;
  margin: auto;
}

#btnScrollToTop {
  font-family: "Raleway", sans-serif;
  position: fixed;
  bottom: 50px;
  right: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background-color: #4a4747;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 6px;
  cursor: pointer;
}

#btnScrollToTop:active {
  background-color: #cc2333;
}

/* .button {
  box-shadow: 0.3rem 0.3rem #f3be12;
} */
