@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

.contactTitle {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 2.6rem;
  font-weight: 500;
  margin-top: 0;
  cursor: default;
}

.subContactTitle {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: default;
}

.contactInfo {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 300;
  font-size: 1rem;
  cursor: default;
}

.signature {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 0.5em;
}

.heroContact {
  background-color: #181616;
}
