@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

.aboutTitle {
  font-family: "Raleway", sans-serif;
  color: #f3be12;
  font-size: 4.5rem;
  font-weight: 600;
  margin-top: 0;
  line-height: 1;
  cursor: default;
}

.aboutSubInfo {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1.4rem;
  margin-top: 1em;
  cursor: default;
}

.subInfoMobile {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1em;
  cursor: default;
}

.testimonial {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 2em;
  cursor: default;
}

.testQuote {
  font-family: "Raleway", sans-serif;
  color: #f3be12;
  font-weight: 500;
  font-size: 1.2rem;
  cursor: default;
}

.heroAbout {
  background-color: #0b0b0b;
  background-image: url(../../images/V2.jpg);
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
}
