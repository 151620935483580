@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

.titleMain {
  font-family: "Raleway", sans-serif;
  color: #f3be12;
  font-size: 5rem;
  font-weight: 600;
  margin-top: 0;
  line-height: 1;
  cursor: default;
}

.titleMobile {
  font-family: "Raleway", sans-serif;
  color: #f3be12;
  font-size: 4rem;
  font-weight: 600;
  margin-top: 0;
  line-height: 1;
  cursor: default;
}

.subInfo {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 1em;
  cursor: default;
}

.subInfoTitleMobile {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 500;
  font-size: 1.4rem;
  margin-top: 1em;
  cursor: default;
}

.navButton {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
}

.navButton:hover {
  cursor: "pointer";
  color: #f3be12;
  background-color: "white";
}

.hero {
  background-color: #0b0b0b;
  background-image: url(../../images/Desktop1.png);
  background-image: url(../../images/AboutBack.jpeg);
  background-image: url(../../images/Desktop2.png);
  /* background-image: url(../../images/BackgroundNew.jpg); */
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  height: 1000px;
}
/* 
backdrop-filter: blur(5px); */
