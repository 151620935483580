@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

.songName {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 2rem;
  font-weight: 600;
  cursor: default;
}

.buttonText {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 1.7rem;
  font-weight: 600;
}

.specialInfo {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: default;
}

.red {
  font-family: "Raleway", sans-serif;
  color: red;
  font-size: 2rem;
  font-weight: 600;
  margin-left: 10px;
  cursor: default;
}
