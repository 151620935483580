@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

.tourTitle {
  font-family: "Raleway", sans-serif;
  color: #f3be12;
  font-size: 3vw;
  font-weight: 600;
  line-height: 1.1em;
  margin-top: 0;

  cursor: default;
}

.tourTitleMobile {
  font-family: "Raleway", sans-serif;
  color: #f3be12;
  font-size: 2.5rem;
  font-weight: 500;
  margin-top: 0;
  line-height: 1.2;
  cursor: default;
}

.tourInfo {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1rem;
  cursor: default;
}

.showingInfo {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1.2rem;
  cursor: default;
}

.tourInfoMobile {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1rem;
  cursor: default;
}

.tourInformationMobile {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-weight: 400;
  font-size: 1.2rem;
  cursor: default;
}
.buttonTextTour {
  font-family: "Raleway", sans-serif;
  color: #ecf0f3;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.4em;
  margin-bottom: 0.3em;
}

.tourHero {
  background-color: #0b0b0b;
}

.location {
  text-decoration: none;
  color: gold;
}
.location:hover {
  text-decoration: none;
  color: gold;
}
